import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import CalendarEventSvg from '../svgs/CalendarEventSvg'
import AddressSvg from '../svgs/AddressSvg'
import { connect } from 'react-redux';
import {getEvents } from '../../actions/MainAction'
import EventCard from '../cards/EventCard';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from 'react-router-dom';
import HandSvg from '../svgs/HandSvg';
export class EventsSection extends Component {
    componentDidMount(){
        this.props.getEvents()
    }
  render() {
    const {eventsData, type='home'}  = this.props;
    // console.log(eventsData)
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 564 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 564, min: 0 },
          items: 1
        }
      };
      
    return (
        <section className='training_section'>
            <div className="container mt-5">
                {
                    eventsData.length != 0?
                    <SectionHeader title="Tədbirlərimiz" />:null
                }
                
                {
                    type == 'all'?
                    <div className="grid-col-3">
                    {
                        eventsData?.map((data, i) => {
                            return (
                                <EventCard key={i} data={data} />
                            )
                        })
                    }
                    </div>:
                     <div className='carousel-cont mt-3'>
                     <Carousel 
                           swipeable={true}
                           draggable={false}
                           showDots={false}
                           responsive={responsive}
                           ssr={true} // means to render carousel on server-side.
                           infinite={true}
                           autoPlay={true}
                           autoPlaySpeed={2000}
                           keyBoardControl={true}
                           customTransition="all 2s"
                           transitionDuration={2000}
                           containerClass="carousel-container"
                         //   deviceType={this.props.deviceType}
                           dotListClass="custom-dot-list-style"
                           itemClass="carousel-item-padding-40-px"
                           
                         >
                           {
                             eventsData.map((data, i) => {
                               return(
                                <EventCard key={i} data={data} />
                               )
                             })
                            
                             
                             
                           }
                         </Carousel>
                        <div className='show_all_events'>
                            <Link to="/events">Bütün tədbirlərə bax <HandSvg /></Link>
                        </div>
                    </div>
                }
                
            </div>
        </section>
    )
  }
}
const mapStateToProps = (state) =>({
    eventsData: state.Data.eventsData
});
const mapDispatchToProps = {getEvents}
export default connect(mapStateToProps, mapDispatchToProps)(EventsSection)
