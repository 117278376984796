import React, { Component } from 'react'

export class Skeleton extends Component {
  render() {
    return (
        <div className="skeleton skeleton-loading">
        <div className="skeleton-image">
            
        </div>
        <div className="skeleton-content">
            <h4></h4>
            <div className="skeleton-description">
            
            </div>
        </div>
    </div>
    )
  }
}

export default Skeleton
