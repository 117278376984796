import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import Card1 from '../cards/Card1'
import { configRedux } from '../../redux/ConfigRedux'
import { getWhyUs } from '../../actions/MainAction'

export class WhyUsSection extends Component {
    componentDidMount(){
        this.props.dispatch(getWhyUs())
    }
  render() {
    const {whyUs} = this.props;
    return (
        <section>
            <div className="whyUsContainer container mt-5">
                <SectionHeader title="Fərqimiz nədir" />
                <div className="container">
                    <div className="grid-col-2">
                        {
                            whyUs?.map((data, i)=>{
                                return(
                                    <Card1 
                                        key={i}
                                        img={data?.image_full_url}
                                        title={data?.title}
                                        description={data?.description}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
  }
}
export default configRedux(WhyUsSection, ["whyUs"])