import { connect } from "react-redux";
export const configRedux=(Component, states=[]) => {
    const getState=(state)=>{
        const lastState={}
        for(const data of states){
            lastState[data]=state.Data[data]
        }
        return lastState;
    }
    const mapStateToProps = (state)=>(getState(state));

    return connect(mapStateToProps)(Component)
}