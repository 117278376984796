import React, { Component } from 'react'

export class CertCard extends Component {
  render() {
    const {data} = this.props;
    return (
        <div  className='trainer_card'>
            <img src={data?.image_full_url} />
            <div className='trainer_card_footer'>
                <h3>{data?.title}</h3>
            </div>
        </div>
    )
  }
}

export default CertCard