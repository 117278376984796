import React, { Component } from 'react'

export class LocationSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        fill="#000"
        viewBox="0 0 24 24"
        width={30}
        height={30}
      
      >
        <style>{".st0{fill:none}"}</style>
        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10v-1c-.6.9-1.2 1.7-1.7 2.4-.4 2.2-1.6 4.1-3.3 5.4-.1-.3-.2-.7-.3-1-.1-.4-.2-.8-.4-1.2-.1-.4-.2-.8-.4-1.1-.1-.1-.3-.2-.5-.3-.7-.2-1.6.1-2.1-.6-.2-.3-.2-.6-.1-1 .2-.3.3-.6.5-.9.2-.4.5-.9.6-1.4-.8-1.2-1.6-2.6-2-3.9h-.1c-.1 0-.1 0-.2-.1-.2-.2-.3-.7-.2-1 0-.5.3-.8.2-1.3 0-.1-.1-.9-.1-.9-.3 0-.8 0-.7-.5v-.1h1.3c.2-.6.6-1.1.9-1.5H12zm6 0c-2.2 0-4 1.8-4 4s4 7 4 7 4-4.8 4-7-1.8-4-4-4zm0 2.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zM8 5.1c.4 0 .7 0 1 .1s.6.3.8.5.5.5.5.8c0 .1 0 .2-.1.2-.1.1-.2.1-.3.1-.3 0-.6 0-.8-.1-.1-.1-.3-.3-.5-.4-.5-.2-1.4 1.1-1.5 1.5-.1.3-.1 1 .4 1.1.3 0 1-.6 1.2-.8.2-.1.3-.2.5-.3.8-.1 1.4.6 1.6 1.3.2.8-1.2 1.4-1.8 1.6-.2.1-.3-.1-.5 0-.5.2-1.1.9-1.1 1.4s-.1 1-.2 1.5c-.1 0-.2-.1-.2-.1v-.2c0-.3-.1-.6-.4-.8-.1 0-.1-.1-.2-.1-.3-.1-.6-.4-.9-.1-.2.2-.4.5-.4.8 0 .1 0 .2.1.3.2.1.4 0 .6 0 .1 0 .2.2.3.3.2.3.3.8.7.8h2c.3.1.8.2 1 .4.1.2.1.4.2.6.4.5 1.1.5 1.7.7.2.1.3.2.3.4 0 .3-.1.7-.2 1s-.2.7-.4.9-.4.3-.6.4c-.4.2-.6.6-.8.9 0 0-.1.2-.2.3-.8-.2-1.5-.5-2.2-1v-.2c-.1-.4-.2-.7-.3-1-.2-.5-.5-1.1-.6-1.6 0-.5.1-1-.2-1.4-.3-.5-1.1-.5-1.6-.8-.4-.4-.9-.8-1.3-1.3V12c0-2.7 1.3-5.1 3.3-6.7.4-.1.7-.2 1.1-.2z" />
        <path d="M0 0h24v24H0z" className="st0" />
        <path d="M0 0h24v24H0z" className="st0" />
      </svg>
    )
  }
}

export default LocationSvg
