import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {type = 'default', title=""} = this.props
    return (
      <div>
        {
            type == 'default'?
            <h2 className="section-header">{title}</h2>:
            <h2 className="section-header-2">Nicol Academy nin fərqi nədədir?</h2>
        }
      </div>
    )
  }
}

export default SectionHeader