import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getStudentsReturn } from '../../actions/MainAction'
import StarSvg from '../svgs/StarSvg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export class StudentReturnCont extends Component {
    state={
        index:0
    }
    componentDidMount(){
        this.props.getStudentsReturn({type: 'single', id: this.props.trainingId});
        let inc = 0;
        setInterval(()=>{
            if(inc < this.props.studentReturns.length-1){
                 inc++;
            }else{
                inc=0
            }
           
            this.setState({index:inc})

        }, 4000)
    }
  render() {
    const {studentReturns} = this.props;
    const {index} = this.state;
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 564 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 564, min: 0 },
          items: 1
        }
      };
    return (
        <div className="thinking_slider-container thining_slider_inner_card">
            <Carousel 
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    customTransition="all 2s"
                    transitionDuration={2000}
                    containerClass="carousel-container"
                  //   deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    
                  >
        {
            studentReturns?.map((data, i)=>{
               
                    return(
                        <div key={i} className="thinking_slider_card">
                            <div className="thinking_slider_header flex items-center">
                                <img src={data?.image_full_url} alt={data?.name} />
                                <div>
                                    <h4>{data?.name}</h4>
                                    <span>{data?.position}</span>
                                    <div className='flex items-center gap-5'>
                                        {
                                            [1, 2, 3, 4, 5].map((item, j)=>{
                                                if(item<=data?.raiting){
                                                    return(
                                                        <StarSvg key={j} />
                                                    )
                                                }else {
                                                    return(
                                                        <StarSvg key={j} color="#5f6160" />
                                                    )
                                                }
                                               
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <p>{data?.text}</p>
                        </div>
                    )
            })
        }
        </Carousel>
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentReturns: state.Data.studentReturns
});
const mapDispatchToProps = {getStudentsReturn}

export default connect(mapStateToProps, mapDispatchToProps)(StudentReturnCont)
