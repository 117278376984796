import {Data} from './MainReducer';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
    Data
});

export const setupStore = () => {
    
    return configureStore({
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
          }),
        reducer: rootReducer
    })
}