import React, { Component } from 'react'
import logo from '../img/nicode1.png'
import { Link } from 'react-router-dom'
export class NotFoundPage extends Component {
  render() {
    return (
      <div className='container mt-5'>
       <div className='flex justify-center column'>
            <img style={{ width: '40%', margin: '20px auto' }} src={logo} />
            <h3 style={{ color: 'var(--primary)', textAlign:'center', fontSize: '160%', marginTop: '20px' }}>Səhifə mövcud deyil</h3>
            <div className='flex justify-center'>
                <Link className='back_home_page' to="/">Ana Səhifə</Link>
            </div>
       </div>
      </div>
    )
  }
}

export default NotFoundPage
