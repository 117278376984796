import React, { Component } from 'react'
import logo from '../img/nicode2.png'
export class Loading extends Component {
  render() {
    return (
      <div className='loading_shadow'>
          <div className='flex column items-center'>
            <img className='loading_logo' src={logo} />
            <span className='loading_text'>Loading...</span>
          </div>
      </div>
    )
  }
}

export default Loading
