import React, { Component } from 'react'
import CalendarEventSvg from '../svgs/CalendarEventSvg';
import AddressSvg from '../svgs/AddressSvg';
import moment from 'moment';
import { Link } from 'react-router-dom';

export class EventCard extends Component {
  render() {
    const {data} = this.props;
    return (
        <Link to={`/events/${data?.id}`} className='events_card'>
            <img src={data?.image_full_url}/>
            <div className='events_card_body'>
                <h2 dangerouslySetInnerHTML={{ __html: data?.name }}></h2>
                <div className='events_card_footer'>
                    <span><CalendarEventSvg />{moment(data?.date).format('DD.MM.YYYY')}</span>
                    <span><AddressSvg />{data?.location}</span>
                    <button className='card_more_view'>Ətraflı bax</button>
                </div>
            </div>
        </Link>
    )
  }
}

export default EventCard
