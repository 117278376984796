import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { configRedux } from '../../redux/ConfigRedux';
import { getStudentsReturn } from '../../actions/MainAction';
import StarSvg from '../svgs/StarSvg';
import SectionHeader from '../SectionHeader';
export class StReturnsSection extends Component {
    componentDidMount(){
        this.props.dispatch(getStudentsReturn());
    }
  render() {
    const {studentReturns} = this.props;
    console.log(studentReturns)
    return (
     <section>
        <div className='container mt-5'>
            <SectionHeader title="Tələbələrimizin rəyləri" />
            <div className='student_rtn_cont'>
                <Carousel
                    autoPlay={true}
                    showIndicators={true}
                    showThumbs={false}
                    infiniteLoop={true}
                    emulateTouch={true}
                    showArrows={false}
                    showStatus={false}
                    >
                       {
                        studentReturns?.map((data, i)=>{
                  
                            return(
                                <div key={i}  className="thinking_slider_card flex column items-center">
                                    <div  className="thinking_slider_header flex items-center">
                                        <img src={data?.image_full_url} alt={data?.name} />
                                        <div>
                                            <h4>{data?.name}</h4>
                                            <span>{data?.position}</span>
                                            <div className='flex items-center gap-5'>
                                                {
                                                    [1, 2, 3, 4, 5].map((item, j)=>{
                                                        if(item<=data?.raiting){
                                                            return(
                                                                <StarSvg key={i} />
                                                            )
                                                        }else {
                                                            return(
                                                                <StarSvg key={i} color="#5f6160" />
                                                            )
                                                        }
                                                        
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <p>{data?.text}</p>
                                </div>
                            )
                            
                        })
                    }
                        </Carousel>
            </div>
        </div>
     </section>
    )
  }
}

export default configRedux(StReturnsSection, ["studentReturns"])
