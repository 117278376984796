import React, { Component } from 'react'

export class MoneySvg extends Component {
  render() {
    return (
        <svg
        style={{ marginTop:'10px' , }}
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 72.62 72.62"
       
      >
        <g data-name="Group 58">
          <g fill="#0000000c2b67" data-name="Group 57">
            <path
              d="M67.253 10.725A191.915 191.915 0 0 0 56.117 0h-50.3A5.823 5.823 0 0 0 0 5.816v18.223a192.8 192.8 0 0 0 10.727 11.135 6.063 6.063 0 0 0 6.014 5.364H66.56a6.064 6.064 0 0 0 6.057-6.057V16.739a6.063 6.063 0 0 0-5.364-6.014ZM3 24.039V5.816A2.82 2.82 0 0 1 5.817 3h50.3a2.815 2.815 0 0 1 2.769 2.341H11.159a5.824 5.824 0 0 0-5.817 5.817v15.649A2.816 2.816 0 0 1 3 24.039Zm5.342 5.341V11.158a2.821 2.821 0 0 1 2.817-2.817h50.3a2.816 2.816 0 0 1 2.769 2.341H16.741a6.064 6.064 0 0 0-6.057 6.057v15.41a2.815 2.815 0 0 1-2.342-2.769Zm61.275 5.1a3.061 3.061 0 0 1-3.057 3.057H16.741a3.06 3.06 0 0 1-3.057-3.057V16.739a3.06 3.06 0 0 1 3.057-3.057H66.56a3.061 3.061 0 0 1 3.057 3.057Z"
              data-name="Path 48"
            />
            <path
              d="M41.65 15.774c-4.952 0-8.981 4.411-8.981 9.834s4.029 9.833 8.981 9.833 8.981-4.411 8.981-9.833-4.029-9.834-8.981-9.834Zm0 16.667c-3.3 0-5.981-3.066-5.981-6.833s2.683-6.834 5.981-6.834 5.981 3.065 5.981 6.834-2.683 6.836-5.981 6.836Z"
              data-name="Path 49"
            />
            <path
              d="M60.144 20.506a5.1 5.1 0 1 0 5.1 5.1 5.111 5.111 0 0 0-5.1-5.1Zm0 7.208a2.1 2.1 0 1 1 2.1-2.1 2.107 2.107 0 0 1-2.1 2.1Z"
              data-name="Path 50"
            />
            <path
              d="M23.509 20.506a5.1 5.1 0 1 0 5.1 5.1 5.11 5.11 0 0 0-5.1-5.1Zm0 7.208a2.1 2.1 0 1 1 2.1-2.1 2.1 2.1 0 0 1-2.1 2.1Z"
              data-name="Path 51"
            />
          </g>
          <path
            fill="#0000000"
            d="M41.323 28.62c-1.6-.11-2.423-.94-2.478-2.184h1.833a.8.8 0 0 0 .645.784v-1.2c-1.686-.23-2.349-.811-2.349-2.1 0-1.151.884-1.962 2.349-2.063v-.82h.746v.829c1.373.11 2.193.728 2.294 2.027h-1.8a.64.64 0 0 0-.5-.627v1.133c1.428.194 2.386.664 2.386 2.009 0 1.152-.718 2.1-2.386 2.211v1.155h-.746Zm0-4.321V23.24c-.378.064-.571.258-.571.525 0 .295.147.442.571.534Zm.746 1.852v1.087c.378-.083.553-.295.553-.58 0-.267-.138-.406-.553-.507Z"
            data-name="Path 52"
          />
        </g>
      </svg>
    )
  }
}

export default MoneySvg
