import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import FaqCard from '../cards/FaqCard'
import img from "../../img/about-home.png"
import { getFaqs } from '../../actions/MainAction'
import { configRedux } from '../../redux/ConfigRedux'
export class FaqSection extends Component {
  state={
    index:999
  }
  componentDidMount(){
    this.props.dispatch(getFaqs())
  }
  changeVisible=(index)=>{
    this.setState({index:index})
  }
  render() {
    const {faqs} = this.props;
    const {index} = this.state;
 
    return (
        <section>
            <div className='mt-3'>
              <SectionHeader title="Tez-tez verilən suallar" />
            </div>
            <div className="container first_section-container delay_column flex justify-between items-center">
                <img className="faq-img" src={img} alt="image" />
                <div className="faq_list-container">
                  {
                    faqs.map((data, i)=>{
                      return(
                        <FaqCard 
                          visible={i==index}
                          key={i}
                          data={data}
                          index={i}
                          changeVisible={this.changeVisible}
                        />
                      )
                    })
                  }
                </div>
            </div>
        </section>
    )
  }
}

export default configRedux(FaqSection, ["faqs"])