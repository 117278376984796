import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import img from '../img/ab.png'
import { configRedux } from '../redux/ConfigRedux'
import { getAbout } from '../actions/MainAction'
import { Helmet } from 'react-helmet'
export class AboutPage extends Component {
  componentDidMount(){
    this.props.dispatch(getAbout());
    window.scrollTo(0, 0);
  }
  render() {
    const {aboutData} = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Haqqımızda - Nicode Academy</title>
          <meta name="description" content={aboutData?.description}/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content={aboutData?.description}/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI " />
          <link rel="canonical" href="https://nicode.az/about"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Haqqımızda - Nicode Academy"/>
          <meta property="og:title" content="Haqqımızda - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/about"/>
          <meta property="og:site_name" content="https://nicode.az/about"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az"/>
          <meta property="og:title" content="Haqqımızda - Nicode Academy"/>
          <meta property="og:description" content={aboutData?.description}/>
        </Helmet>
        <PageHeaderSection title={aboutData?.title} />
          <section>
            <div className="container flex mt-3 about-container items-center">
                <img src={img} />
                <div>
                    <h2>{aboutData?.title}</h2>
                    <p className="mt-3" dangerouslySetInnerHTML={{ __html: aboutData?.description }}></p>
                </div>
            </div>
        </section>
      </div>
    )
  }
}

export default configRedux(AboutPage, ["aboutData"])