import React, { Component } from 'react'

export class RightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 24 24"
        fill='#fff'
      >
        <path d="M14.707 20.707a1 1 0 0 1-1.414-1.414L19.586 13H2a1 1 0 0 1 0-2h17.586l-6.293-6.293a1 1 0 0 1 1.414-1.414l8 8a1 1 0 0 1 .216.325.986.986 0 0 1 0 .764 1 1 0 0 1-.216.325Z" />
      </svg>
    )
  }
}

export default RightSvg