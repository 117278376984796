import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import TrainingAboutSection from '../components/sections/TrainingAboutSection'
import { configRedux } from '../redux/ConfigRedux'
import { useNavigate, useParams } from 'react-router-dom';
import { getTrainingData } from '../actions/MainAction';
import WhatSayStudents from '../components/sections/WhatSayStudents';
import StudentReturnCont from '../components/sections/StudentReturnCont';
import TrainingQuestions from '../components/sections/TrainingQuestions';
import TrainingReturn from '../components/sections/TrainingReturn';
import {Helmet} from "react-helmet";
function withHooks(Component){
  return props=> <Component {...props} params={useParams()} />
}
export class SingleTrainingPage extends Component {
  state={
    manualRender: true
  }
  componentDidMount(){
    this.props.dispatch(getTrainingData(this.props.params.id));
    window.scrollTo(0, 0);
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.params?.id != this.props.params?.id){
      this.setState({manualRender:false});
      setTimeout(()=>{this.setState({manualRender:true})}, 50)
      this.props.dispatch(getTrainingData(this.props.params.id))
    }
  }

  convertText(html){
    return />([^<]+)</g.exec(html)
  }
  render() {
    const {trainingData, studentReturns} = this.props;
    const {manualRender} = this.state;
// console.log(this.convertText(trainingData.description))
    return (
      <div>
         <Helmet>
          <meta charSet="utf-8" />
          <title>Təlim Haqqında - Nicode Academy</title>
          <meta name="description" content={trainingData?.sub_title}/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content={trainingData?.sub_title}/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI" />
          <link rel="canonical" href="https://nicode.az/"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content={`${trainingData?.title} - Nicode Academy`}/>
          <meta property="og:title" content={`${trainingData?.title} - Nicode Academy`}/>
          <meta property="og:url" content="https://nicode.az/trainings"/>
          <meta property="og:site_name" content="https://nicode.az/trainings"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az/trainings"/>
          <meta property="og:title" content={`${trainingData?.title} - Nicode Academy`}/>
          <meta property="og:description" content={trainingData?.sub_title}/>
        </Helmet>
        <PageHeaderSection title={trainingData?.title} />
        <section >
            <div className="container">
                <div className="single_training_header">
                    <img src={trainingData?.image_full_url} alt={trainingData?.title} />
                    {/* <TrainingAboutSection data={trainingData} /> */}
                    <div>
                      
                      <TrainingAboutSection data={trainingData} />
                    </div>
                </div>
                {/* <TrainingAboutSection data={trainingData} /> */}
                <div className='training_data-body'>
                  <div className="mt-3">
                    <h4 style={{  display:'inline-block' }} className='single_training-subtitle'>{trainingData?.sub_title}</h4>
                  </div>
                  {
                    this.convertText(trainingData.description)?
                    <div className='mt-5'>
                    <h4 className='single_training-subtitle'>Təlim haqqında</h4>
                    <p className='single_training-text' dangerouslySetInnerHTML={{ __html: trainingData?.description }}></p>
                  </div>:null
                  }
                  
                  {
                    trainingData?.trainer?.map((item, k)=>{
                      if(this.convertText(item?.user?.description)){
                        return(
                        <div key={k} className='mt-5'>
                          <h4 className='single_training-subtitle'>{item?.user?.sub_position} haqqında</h4>
                          <p className='single_training-text' dangerouslySetInnerHTML={{ __html: trainingData?.trainer[0]?.user?.description }}></p>
                        </div>
                      )
                      }
                    })
                  }
                  
                  {
                    this.convertText(trainingData?.training_progress)?
                    <div className='mt-5'>
                      <h4 className='single_training-subtitle'>Təlim gedişatı</h4>
                      <p className='single_training-text' dangerouslySetInnerHTML={{ __html: trainingData?.training_progress }}></p>
                    </div>:null
                  }
                  {/* {
                    trainingData?.programs?.map((data, i)=>{
                      return(
                        <div key={i} className='mt-5'>
                          <h4 className='single_training-subtitle'>{data?.title}</h4>
                          <p className='single_training-text' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                        </div>
                      )
                    })
                  } */}
                </div>
                
            </div>
        </section>
        {
          trainingData != ''?
          <TrainingQuestions data={trainingData?.questions} />:null
        }
        <section>
          <div className='container'>
            {
              studentReturns?.length != 0?
              <h3 style={{ textAlign:'center', marginTop:'39px' }} className='tranining_about_title'>Təlim haqqında fikirlər</h3>:null
            }
            
            {
              manualRender?
              <StudentReturnCont trainingId={this.props.params.id} />:null
            }
          </div>
        </section>
        <TrainingReturn trainingId={trainingData?.id}  />
        {/* <WhatSayStudents apply={true} trainingId={this.props.params.id} /> */}
      </div>
    )
  }
}

export default configRedux(withHooks(SingleTrainingPage), ["trainingData", "studentReturns"])