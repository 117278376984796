import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Card2 extends Component {
  render() {
    const {data} = this.props;
    // console.log(data)
    return (
        <Link to={`/training/${data?.path}`}  className="card-2 card-2-carousel">
            <div className='card-2-header'>
              <img src={data?.image_full_url} alt={data?.title} />
            </div>
            <div className="card-2-body">
               
                
                <h3>{data?.title}</h3>
                <div className="flex justify-between items-center">
                    <span>{data?.duration}</span>
                    <button className='card_more_view'>Ətraflı bax</button>
                </div>
                {/* <div className='card-2-footer'>
                  <div className='flex items-center gap-20'>
                    <img src={data?.trainer?.user?.image_full_url} />
                    <div >
                      <h4>{data?.trainer?.user?.name}</h4>
                      <p>Müəllim</p>
                    </div>
                  </div>
                  <b>{data?.price}</b>
                </div> */}
            </div>
        </Link>
    )
  }
}

export default Card2