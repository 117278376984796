import React, { Component } from 'react'
import HeaderBox from '../components/HeaderBox'
import WhyUsSection from '../components/sections/WhyUsSection'
import TrainingSection from '../components/sections/TrainingSection'
import ContactSection from '../components/sections/ContactSection'
import FaqSection from '../components/sections/FaqSection'
import StReturnsSection from '../components/sections/StReturnsSection'
import WhatSayStudents from '../components/sections/WhatSayStudents'
import {Helmet} from "react-helmet";
import EventsSection from '../components/sections/EventsSection'
export class MainPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ana səhifə - Nicode Academy</title>
          <meta name="description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI " />
          <link rel="canonical" href="https://nicode.az/"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Ana səhifə - Nicode Academy"/>
          <meta property="og:title" content="Ana səhifə - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/"/>
          <meta property="og:site_name" content="https://nicode.az/"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az"/>
          <meta property="og:title" content="Ana səhifə - Nicode Academy"/>
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
        </Helmet>
        <HeaderBox/>
        <TrainingSection type="home" />
        <WhyUsSection />
        <FaqSection  />
        {/* <StReturnsSection /> */}
        <WhatSayStudents />
        <EventsSection type="home" />
        <ContactSection />
      </div>
    )
  }
}

export default MainPage