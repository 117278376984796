import React, { Component } from 'react'

export class MenuOpenSvg extends Component {
  render() {
    const {color} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeWidth={2}
          d="M5 12h15M5 17h15M5 7h15"
        />
      </svg>
    )
  }
}

export default MenuOpenSvg