import React, { Component } from 'react'
import PageHeaderSection from '../components/sections/PageHeaderSection'
import TrainersSection from '../components/sections/TrainersSection'
import {Helmet} from "react-helmet";
export class TrainersPage extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
   
  }
  render() {
  
    return (
      <div className='trainer_main_cont'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Müəllimlərimiz - Nicode Academy</title>
          <meta name="description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta property="og:type" content="website" /> 
          <meta name="robots" content="all, index" /> 
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
          <meta name="keywords" content="Nicode Academy, Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI" />
          <link rel="canonical" href="https://nicode.az/"/>
          <meta property="og:locale" content="az_AZ"/>
          <meta property="og:type" content="Müəllimlərimiz - Nicode Academy"/>
          <meta property="og:title" content="Müəllimlərimiz - Nicode Academy"/>
          <meta property="og:url" content="https://nicode.az/trainers"/>
          <meta property="og:site_name" content="https://nicode.az/trainers"/>
          <meta property="og:image" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>
          <meta property="og:image:secure_url" content="https://nicode.az/static/media/nicode2.65d12d39210d7ec2788e.png"/>

          <meta name="twitter:site" content="nicode.az"/>
          <meta property="og:url" content="http://nicode.az/trainers"/>
          <meta property="og:title" content="Müəllimlərimiz - Nicode Academy"/>
          <meta property="og:description" content="Nicode, Nicode.az, Nicode Academy, Nicode Academy data science, data analitika, SQL, Power BI üzrə ixtisaslaşmış peşəkar tədris mərkəzidir."/>
        </Helmet>
        <PageHeaderSection title="Müəllimlərimiz" />
        <TrainersSection />
      </div>
    )
  }
}

export default TrainersPage
