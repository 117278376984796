import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import { configRedux } from '../../redux/ConfigRedux';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/Alerts';

export class ContactSection extends Component {
    state={
        first_name:'',
        last_name:'',
        email:'',
        phone_number:'',
        text:'',
        btnDisable:false
    }

    saveBtn(data, e){
        e.preventDefault();
        if(data?.first_name != '' && data?.last_name != '' && data?.email != '' && data?.phone_number != '' && data?.text != ''){
            this.setState({btnDisable:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            let url = 'public-questions';
            this.props.dispatch(insertNewData(url, formData))
            .then(resp=>{
                this.setState({btnDisable:false})

                this.setState({
                    first_name:'',
                    last_name:'',
                    email:'',
                    phone_number:'',
                    text:''
                })
                if(resp == 'success'){
                    mixinAlert('success', "Sualınız göndərildi.");
                }else{
                    mixinAlert('error', "Xəta başverdi.");
                }
            })
        }else{
            mixinAlert('error', "Zəhmət olmasa bütün xanaları doldurun.");
        }
    }
  render() {
    const {first_name, last_name, email, phone_number,text, btnDisable} = this.state;
    return (
        <section>
            <div className="full_screen-container bg-gray mt-5">
                <div className="container">
                    <SectionHeader title="Bizimlə əlaqə" />
                    <form >
                        <div className="grid-col-4 mt-3">
                            <div className="inp-cont">
                                <input value={first_name} onChange={(e)=>this.setState({first_name:e.target.value})} type="text" placeholder="Ad" />
                            </div>
                            <div className="inp-cont">
                                <input value={last_name} onChange={(e)=>this.setState({last_name:e.target.value})} type="text" placeholder="Soyad" />
                            </div>
                            <div className="inp-cont">
                                <input value={email} onChange={(e)=>this.setState({email:e.target.value})} type="email" placeholder="E-poçt" />
                            </div>
                            <div className="inp-cont">
                                <input value={phone_number} onChange={(e)=>this.setState({phone_number:e.target.value})} type="text" placeholder="Nömrə" />
                            </div>
                        </div>
                        <textarea value={text} onChange={(e)=>this.setState({text:e.target.value})} className="question-textarea" placeholder="Sualınız"></textarea>
                        <div className="flex justify-end">
                            <button disabled={btnDisable} onClick={this.saveBtn.bind(this, this.state)} className="question_send-Btn">Göndər</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
  }
}

export default configRedux(ContactSection, [""])