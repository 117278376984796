import React, { Component } from 'react'
import WpSvg2 from '../svgs/WpSvg2'
import img from "../../img/wp-assistant.avif"
import WpSvg3 from '../svgs/WpSvg3'
import CloseSvg from '../svgs/CloseSvg'
import { connect } from 'react-redux'
export class WpApplyButton extends Component {
    state={
        visible:false
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({visible:true})
        }, 15000)
    }
  render() {
    const {aboutData} = this.props;
    const {visible} = this.state;
    return (
      <div className='wp_apply_btn'>
        {
            visible?
             <div className='wp_apply_cont'>
                <button onClick={()=>this.setState({visible:false})} className='wp_apply_close_btn'>
                    <CloseSvg color={"var(--primary)"} size={25} />
                </button>
                <img className='wp-appy-img' src={img} />

                <p className='wp-apply-text1'>Sənə necə kömək edə bilərik?</p>
                <p className='wp-apply-text1'>Sualın varsa cavablamağa hazıram!</p>
                <a className='apply-wp-btn' target='_blank' href={`https://wa.me/${aboutData?.number}`}><WpSvg3 /> Bizə yaz!</a>
            </div>:null
        }
       
        <button onClick={()=>this.setState({visible: !visible})} style={{background:'none'}}>
            <WpSvg2 />
        </button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    aboutData: state.Data.aboutData
})
export default connect(mapStateToProps)(WpApplyButton)
